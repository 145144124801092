import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators
} from "@angular/forms";
import { Store } from '@ngrx/store';
import {
  TravelTicketActions,
  State,
  selectTravelTicketError,
  selectTravelTicketLoading,
  selectTravelTicketIsAssigned,
  selectCurrentExpense,
  selectTravelTicketAssigned
} from '@ems-gui/expense/util-web-ngrx';
import { selectTravelTicket } from '@ems-gui/expense/util-web-ngrx';
import { Subject, Subscription } from 'rxjs';
import {
  Expense,
  ExpenseType,
  TravelTicket,
  TravelTicketDetail
} from "@ems-gui/shared/util-core";
import { take, takeUntil, tap } from 'rxjs/operators';

export enum travelTicketExpenseTypeMapping {
  'Auto Rental' = 'Vehicle Rental',
  'Conference Room' = 'Conference & Seminar Fees',
  'Flight' = 'Airfare',
  'Hotel' = 'Lodging Expense',
  'Transportation' = 'Taxi'
};

@Component({
  selector: "travel-ticket-view",
  templateUrl: "./travel-ticket-view.component.html",
  styleUrls: ["./travel-ticket-view.component.scss"]
})
export class TravelTicketViewComponent implements OnInit, OnDestroy {
  private unsubscribe$ = new Subject<void>();
  public isAssigned$ = this.store$.select(selectTravelTicketIsAssigned);
  public travelTicketForm: FormGroup;
  public expense$ = this.store$.select(selectCurrentExpense);
  public travelTicketTableData: TravelTicketDetail[] = [];
  protected subscriptions: Subscription[] = [];
  displayedColumns: string[] = ["date", "detailType", "vendor", "traveler", "TTNumber", "purpose", "assign"];
  travelTicketTextValue: string;
  travelTicket: Partial<TravelTicket> = {};
  travelTicketAssigned$ = this.store$.select(selectTravelTicketAssigned);
  travelTicket$ = this.store$.select(selectTravelTicket);
  travelTicketError$ = this.store$.select(selectTravelTicketError);
  travelTicketLoading$ = this.store$.select(selectTravelTicketLoading);
  submitted = false;
  form: FormGroup = this.fb.group({
    travelTicketNumber: new FormControl('', {
      validators: [ Validators.required]
    })
  });
  showClearIcon = false;
  @Input() public formId = 'edit-expense'
  @Input() public expense: Expense;
  @Input() public expenseTypes: ExpenseType[];
  @Output() public travelTicketAssigned = new EventEmitter<Expense>();
  @Output() public travelTicketUnassigned = new EventEmitter<Expense>();


  public isBackground = false;


  constructor(
    private fb: FormBuilder,
    private store$: Store<State>
  ) {}

  ngOnInit() {
    this.travelTicket$.pipe(
      takeUntil(this.unsubscribe$),
      tap((travelTicket) => this.travelTicketSliceListener(travelTicket))
    ).subscribe();

    this.travelTicketAssigned$.pipe(
      takeUntil(this.unsubscribe$),
      tap((travelTicket) => {
        if(!travelTicket) {
          this.travelTicketTextValue = '';
          return;
        }
        const detail = travelTicket.details[0];
        this.travelTicketTextValue = `${travelTicket.name} (${detail.name})`;
      })
    ).subscribe();

    this.form.valueChanges.subscribe((form) => {
      this.showClearIcon = form.travelTicketNumber.length > 0;
    });
  }

  protected travelTicketSliceListener(travelTicket: Partial<TravelTicket>) {
    this.decideWhatToShow(travelTicket);
    if(!travelTicket) {
      this.travelTicketTableData = [];
      return;
    }
    this.travelTicketTableData = travelTicket.details;
    this.travelTicket = travelTicket;
  }

  get travelTicketNumber() {
    return this.form.get('travelTicketNumber');
  }

  get travelTicketNumberRequired() {
    return this.travelTicketNumber.errors?.required;
  }

  onSubmit() {
    this.store$.dispatch(TravelTicketActions.clearTravelTicketError());
    this.submitted = true;
    if (this.form.invalid) {
      return;
    }
    this.store$.dispatch(
      TravelTicketActions.requestTravelTicketByTicketName(
        { ticketName: this.form.value.travelTicketNumber }
      )
    );

    this.submitted = false;
  }

   private getCustomCase() {
    const travelTicket = Number(this.travelTicket.customCase);
    const expense = Number(this.expense.salesforceId);
    console.log(travelTicket);
    console.log(expense);
    if(!travelTicket || travelTicket === expense) return expense;

    return travelTicket;
  }

  dispatchAssignTDR(travelTicketDetail: TravelTicketDetail) {
    const expenseCopy = {
      ...this.expense,
      travelTicketDetail: ''
    };
    expenseCopy.isBillable = this.travelTicket.billable ?? this.expense.isBillable;
    expenseCopy.jobCode = Number(this.travelTicket.jobCode) ?? this.expense.jobCode;
    expenseCopy.salesforceId = this.getCustomCase();
    expenseCopy.description = `${this.travelTicket?.traveler} - ${this.travelTicket?.name} - ${travelTicketDetail?.detailType} - ${this.travelTicket?.purpose}`;

    expenseCopy.travelTicket = this.travelTicket.sfId ?? expenseCopy.travelTicket;
    expenseCopy.travelTicketDetail = travelTicketDetail.sfId;

    const expenseTypeTitle = travelTicketExpenseTypeMapping[
      travelTicketDetail.detailType as
        keyof typeof travelTicketExpenseTypeMapping
    ];
    const expenseType = this.expenseTypes.find(({ name }) => name === expenseTypeTitle);
    if(expenseType) {
      expenseCopy.type = expenseType.id;
    }
    this.store$.dispatch(
      TravelTicketActions.setTravelTicket({
        travelTicket: this.travelTicket as TravelTicket,
        isAssigned: true
      })
    );
    this.travelTicketTextValue = `${this.travelTicket.name} (${travelTicketDetail.name})`;
    this.store$.dispatch(
      TravelTicketActions.assignTravelTicketDetailToExpense({
        expense: expenseCopy
      })
    );

    this.travelTicketAssigned.emit(expenseCopy);
  }

  assignTDR(travelTicket: TravelTicketDetail) {
    return this.dispatchAssignTDR(travelTicket);
  }

  dispatchRemoveTDR() {
    const expenseCopy = {
      ...this.expense
    };
    expenseCopy.travelTicket = '';
    expenseCopy.travelTicketDetail = '';
    this.store$.dispatch(
      TravelTicketActions.removeTravelTicketDetailFromExpense({
        expense: expenseCopy
      })
    );
    this.resetTravelTicket();
    this.travelTicketUnassigned.emit(expenseCopy);
  }

  removeTDR() {
    return this.dispatchRemoveTDR();
  }

  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
    this.store$.dispatch(TravelTicketActions.clearTravelTicket());
  }

  public setIsFocusedTrue() {
    this.isBackground = true;
  }

  private decideWhatToShow(tt: Partial<TravelTicket>) {
    if(typeof tt !== 'object' || !tt.details || !tt.details.length) {
      return this.isBackground = false;
    }

    this.isBackground = true;
  }

  public setIsFocusedFalse() {
     this.travelTicket$.pipe(
       take(1),
       tap((tt) => this.decideWhatToShow(tt))
     ).subscribe();
  }

  public resetTravelTicket() {
    this.store$.dispatch(TravelTicketActions.clearTravelTicket());
    this.travelTicketNumber.setValue('');
  }

  public clearTravelTicketInput() {
    this.isAssigned$.pipe(
      take(1),
      tap((isAssigned) => {
        if(isAssigned) {
          return this.removeTDR();
        }
        this.resetTravelTicket();
      })
    ).subscribe();
  }
}
