import {
  Component,
  ElementRef,
  EventEmitter,
  HostListener,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { DatepickerComponent } from '@ems-gui/expense/ui-web-components';
import { environment } from '@ems-gui/expense/util-web-infrastructure';
import {
  CustomValidators,
  MAX_DESCRIPTION_LENGTH,
  OnChange
} from '@ems-gui/shared/util-core';
import { Subject } from 'rxjs';
import {
  debounceTime,
  distinctUntilChanged,
  filter,
  takeUntil,
  tap,
} from 'rxjs/operators';
import { FormManagerService } from '@src/app/services/form-manager.service';

@Component({
  selector: 'ems-mileage-expense-form-new',
  templateUrl: './mileage-expense-form-new.component.html',
  styleUrls: ['./mileage-expense-form-new.component.scss'],
})
export class MileageExpenseFormNewComponent implements OnInit, OnDestroy {
  @Input() public formId = 'mileage-expense-modal-new';
  @OnChange(function (this: MileageExpenseFormNewComponent, dismiss, changes) {
    if (dismiss) {
      const dismissChange = changes.currentValue;
      if (dismissChange) {
        this.dismissing = true;
        this.form.get('startMileage').setValue(null);
        this.form.get('endMileage').setValue(null);
        this.distance.nativeElement.value = null;
        this.form.get('jobCode').setValue(null);
        this.form.get('description').setValue(null);
        this.form.get('message').setValue(null);
        this.form.get('isBillable').setValue(false);
        this.form.get('salesforceId').setValue(null);
        this.jobCodeEl.clear();
        this.form.markAsPristine();
        this.form.markAsUntouched();
        this.form.updateValueAndValidity();
        this.showNote = false;
        this.showSalesforce = false;
        this.dismissing = false;
        this.jobCodeClicked = false;
      }
    }
  })
  @Input()
  dismiss;
  @Input() jobCodes;
  @Input() favorites;
  @Input() reimbursement;
  @Input() salesforceCases;
  @Input() favoriteSalesforceCases;
  @Input() autoSavedId;
  @Input() proxy;
  @Input() submitted;
  @Input() mileageExpenseType;
  @Output() newMileageFormChange: EventEmitter<any> = new EventEmitter<any>();
  @Output() newMileageStatusChange: EventEmitter<any> = new EventEmitter<any>();
  @Output() getNewReimbursement: EventEmitter<any> = new EventEmitter<any>();
  @Output() favoriteSalesforce: EventEmitter<any> = new EventEmitter<any>();
  @Output() favoriteJobCode: EventEmitter<any> = new EventEmitter<any>();

  showNote = false;
  showSalesforce = false;
  form: FormGroup;
  unsubscribe$: Subject<void>;
  jobCodeClicked = false;
  totalMileage;
  currentStart;
  currentEnd;
  selectedDate;
  dismissing;
  maxDate = new Date();
  readonly maxLength = MAX_DESCRIPTION_LENGTH;
  @ViewChild('distance', { static: false }) distance: ElementRef;
  @ViewChild('jobCodeEl', { static: false }) jobCodeEl;
  @ViewChild(DatepickerComponent) datepicker: DatepickerComponent;
  url = `${environment.apiURL}/expenses/`;
  params = {
    type: 0,
    extraInfo: JSON.stringify({ start: 0, end: 0 }),
    transactionDate: new Date().toISOString(),
    paymentType: "mileage"
  };
  lastValidStart = 0;
  lastValidEnd = 0;
  showBillable = false;
  innerWidth;

  constructor(
    private readonly fm: FormManagerService,
    private fb: FormBuilder
  ) {
    this.form = this.fb.group(
      {
        transactionDate: new FormControl(new Date(), Validators.required),
        description: new FormControl(null,{
          validators: [Validators.required],
        }),
        startMileage: new FormControl(null, [
          Validators.required,
          CustomValidators.validOdometerAmount,
        ]),
        endMileage: new FormControl(null, [
          Validators.required,
          CustomValidators.validOdometerAmount,
        ]),
        isBillable: new FormControl(true, Validators.required),
        jobCode: new FormControl(null, {
          validators: [Validators.required],
        }),
        message: new FormControl(null),
        salesforceId: new FormControl(null),
      },
      {
        validators: [
          CustomValidators.validDistance('startMileage', 'endMileage'),
        ],
      }
    );
    this.unsubscribe$ = new Subject<void>();
  }

  get transactionDate() {
    return this.form.get('transactionDate');
  }

  get jobCode() {
    return this.form.get('jobCode');
  }

  get description() {
    return this.form.get('description');
  }

  get message() {
    return this.form.get('message');
  }

  get startMileage() {
    return this.form.get('startMileage');
  }

  get endMileage() {
    return this.form.get('endMileage');
  }

  get salesforceId() {
    return this.form.get('salesforceId');
  }

  get isBillable() {
    return this.form.get('isBillable');
  }

  get startMileageIsRequired() {
    const startMileage = this.startMileage;

    return startMileage.errors?.required && this.submitted;
  }

  get startMileageIsInvalid() {
    const startMileage = this.startMileage;
    return (
      startMileage.errors?.amountNotValid &&
      (startMileage.dirty || this.submitted)
    );
  }

  get endMileageIsRequired() {
    const endMileage = this.endMileage;

    return endMileage.errors?.required && this.submitted;
  }

  get endMileageIsInvalid() {
    const endMileage = this.endMileage;

    return (
      endMileage.errors?.amountNotValid && (endMileage.dirty || this.submitted)
    );
  }

  get distanceIsInvalid() {
    return this.form.errors?.distanceInvalid && this.endMileage.dirty;
  }

  get jobCodeIsRequired() {
    const jobCode = this.jobCode;

    return jobCode.invalid && this.submitted;
  }

  get descriptionIsRequired() {
    const description = this.description;

    return description.invalid && this.submitted;
  }

  ngOnInit() {
    this.params.type = this.mileageExpenseType.id;
    this.form.valueChanges
      .pipe(
        takeUntil(this.unsubscribe$),
        filter(() => !this.form.pristine && !this.dismissing),
        debounceTime(750),
        distinctUntilChanged(),
        tap((value: any) => {
          const mileageForm = {
            ...value,
            extraInfo: {},
          };

          const start = value.startMileage?.replace(/,/g, '');
          const end = value.endMileage?.replace(/,/g, '');
          this.lastValidStart = this.startMileage.valid
            ? +start
            : this.lastValidStart;
          this.lastValidEnd =
            this.endMileage.valid && !this.form.errors?.distanceInvalid
              ? +end
              : this.lastValidEnd;
          mileageForm.extraInfo.start = this.lastValidStart;
          mileageForm.extraInfo.end = this.lastValidEnd;
          mileageForm.transactionDate = new Date(
            value.transactionDate
          ).toISOString();
          this.selectedDate = new Date(value.transactionDate).toISOString();
          mileageForm.jobCode = +value.jobCode;
          mileageForm.paymentType = 'mileage';
          mileageForm.type = this.mileageExpenseType.id;
          delete mileageForm.startMileage;
          delete mileageForm.endMileage;
          mileageForm.salesforceId = mileageForm.salesforceId
            ? +mileageForm.salesforceId
            : '0';

          this.checkMileage();

          this.newMileageFormChange.emit(mileageForm);
        })
      )
      .subscribe();

    this.form.statusChanges
      .pipe(
        takeUntil(this.unsubscribe$),
        tap((value) => {
          const valid = value === 'VALID';
          this.newMileageStatusChange.emit(valid);
        })
      )
      .subscribe();

    this.form
      .get('jobCode')
      .valueChanges.pipe(
        takeUntil(this.unsubscribe$),
        tap((value: any) => {
          this.handleJobCodeChange(value);
        })
      )
      .subscribe();

    this.form
      .get('salesforceId')
      .valueChanges.pipe(
        takeUntil(this.unsubscribe$),
        tap((value: any) => {
          const filteredCases = this.salesforceCases.filter((c) => c.id === value);
          // Find the matching jobCode record if custom case ahs approval jobCode
          if(filteredCases.length > 0 && filteredCases[0].expense_approval_job_code !== null) {
            const approval_jobCode = this.jobCodes.filter
              ((j) => j.id === filteredCases[0].expense_approval_job_code
            );
            // if the approval jobCode is part of the dropdown assign jobCode to expense
            if(approval_jobCode.length > 0) {
              this.jobCode.setValue(approval_jobCode[0].id);
            }
          }
        })
      )
      .subscribe();

    this.handleJobCodeChange(this.jobCode.value);

    this.innerWidth = window.innerWidth;
    this.fm.register(this.formId, this.form);
  }

  handleJobCodeChange(value: any) {
    if(value) {
      this.showBillable = true;
      const jobCodeValue = this.jobCodes.find(p => p.id == value);
      this.isBillable.setValue(jobCodeValue.company.billable);
    }
    // Set the jobCode vaue to default when the job code is removed
    else if(value === 0)  {
      this.jobCode.setValue(null);
    }
  }

  onToggleNote() {
    this.showNote = !this.showNote;
  }

  onToggleSalesforce() {
    this.showSalesforce = !this.showSalesforce;
    if (!this.showSalesforce) {
      this.form.get('salesforceId').setValue('0');
    }
  }

  onJobCodeClick() {
    this.jobCodeClicked = true;
  }

  checkMileage() {
    const start = this.startMileage.value?.replace(/,/g, '');
    const end = this.endMileage.value?.replace(/,/g, '');

    const mileage = end - start;
    if (mileage > 0 && (end !== null || start !== null)) {
      this.distance.nativeElement.value = mileage;
      this.getNewReimbursement.emit({
        start,
        end,
        transactionDate: this.selectedDate,
      });
      this.startMileage.setErrors(null);
      this.endMileage.setErrors(null);
    }
  }

  onFavoriteJobCode(id) {
    this.favoriteJobCode.emit(id);
  }

  onFavoriteSalesforceCase(id) {
    this.favoriteSalesforce.emit(id);
  }

  @HostListener('window:resize', ['$event'])
  onResize(event) {
    this.innerWidth = event.target.innerWidth;
  }

  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }
}
