 import {
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import {
  FormBuilder,
  FormGroup,
  FormGroupDirective,
  Validators,
} from '@angular/forms';
import { DatepickerComponent } from '@ems-gui/expense/ui-web-components';
import {
  environment,
  UtilsService,
} from '@ems-gui/expense/util-web-infrastructure';
import {
  ConvertToDollarsPipe,
  CustomValidators,
  Expense,
  MAX_DESCRIPTION_LENGTH,
  OnChange,
} from '@ems-gui/shared/util-core';
import { ExpenseService } from '../../../../../src/app/services/expense.service';
import { Subject } from 'rxjs';
import { filter, takeUntil, tap } from 'rxjs/operators';
import { Store, select } from '@ngrx/store';
import {
  selectAllExpenseTypesForPersonalExpense,
  selectExpenseOcrMismatch,
  selectUserHasCreditCard,
  selectCurrentModal,
  selectReceiptData,
  State,
  NewActions,
} from '@ems-gui/expense/util-web-ngrx';
import { FormManagerService } from '@src/app/services/form-manager.service';
import { CurrencyPipe } from '@angular/common';

export interface ExpenseOption {
  text: string;
  selected: boolean;
  value: any;
}

@Component({
  selector: 'ems-expense-form-new',
  templateUrl: './expense-form-new.component.html',
  styleUrls: ['./expense-form-new.component.scss'],
  providers: [ConvertToDollarsPipe, CurrencyPipe],
})
export class ExpenseFormNewComponent implements OnInit, OnDestroy {
  constructor(
    private fb: FormBuilder,
    private utils: UtilsService,
    private expenseService: ExpenseService,
    private store$: Store<State>,
    private formManager: FormManagerService,
    private currencyPipe: CurrencyPipe,
    private convertToDollarsPipe: ConvertToDollarsPipe,
  ) {
    this.store$
      .pipe(
        select(selectAllExpenseTypesForPersonalExpense),
        takeUntil(this.unsubscribe$)
      )
      .subscribe((expenseTypes) => {
        this.personalExpenseTypes = expenseTypes;
      });
  }

  @OnChange(function (this: ExpenseFormNewComponent, autoSavedId) {
    if (
      autoSavedId &&
      this.autoSavedId &&
      this.form.get('paymentType').value !== 'company'
    ) {
      this.url = `${environment.apiURL}/expenses/${+this.autoSavedId}/receipt`;
      this.method = 'put';
    } else if (
      autoSavedId &&
      this.autoSavedId &&
      this.form.get('paymentType').value === 'company'
    ) {
      this.url = `${environment.apiURL}/expenses/${+this
        .autoSavedId}/receipt/verify-with-ocr`;
      this.method = 'put';
    }
  })
  @Input()
  autoSavedId;
  @Input() public formId = 'new-expense';

  @OnChange(function (this: ExpenseFormNewComponent, dismiss) {
    this.onDismiss(dismiss);
  })
  @Input()
  dismiss;

  /**
   * Inputs
   */
  @Input() expenseTypes;
  personalExpenseTypes;
  @Input() jobCodes;
  @Input() favoriteJobCodes;
  @Input() salesforceCases;
  @Input() favoriteSalesforceCases;
  @Input() itemizationStatus;
  @Input() proxy;
  @Input() submitted;
  @Output() removeReceipt: EventEmitter<any> = new EventEmitter<any>();

  maxDate = new Date();

  /**
   * Outputs
   */
  @Output() favoriteJobCode: EventEmitter<any> = new EventEmitter<any>();
  @Output() favoriteSalesforce: EventEmitter<any> = new EventEmitter<any>();
  @Output() formChange: EventEmitter<any> = new EventEmitter<any>();
  @Output() statusChange: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output() amountStatusChange: EventEmitter<boolean> =
    new EventEmitter<boolean>();
  @Output() receiptData: EventEmitter<{file: File, rotationAngle: number}> =
    new EventEmitter<{file: File, rotationAngle: number}>();
  @Output() fileSizeError: EventEmitter<any> = new EventEmitter<any>();
  @Output() fraudulentExpense: EventEmitter<boolean> =
    new EventEmitter<boolean>();

  /**
   * Form
   */
  form: FormGroup = this.fb.group(this.expenseService.form());
  get vendor() {
    return this.form.get('vendor');
  }
  get address() {
    return this.form.get('address');
  }
  get amount() {
    return this.form.get('amount');
  }
  get description() {
    return this.form.get('description');
  }
  get transactionDate() {
    return this.form.get('transactionDate');
  }
  get fraudulent() {
    return this.form.get('fraudulent');
  }
  get message() {
    return this.form.get('message');
  }
  get jobCode() {
    return this.form.get('jobCode');
  }
  get paymentType() {
    return this.form.get('paymentType');
  }
  get type() {
    return this.form.get('type');
  }
  get isBillable() {
    return this.form.get('isBillable');
  }

  get date() {
    return this.form.get('transactionDate');
  }

  get amountIsRequired() {
    const amount = this.amount;
    return amount.errors?.required && this.submitted;
  }

  get dateIsRequired() {
    const date = this.date;
    return date.invalid && this.submitted;
  }

  get amountIsInvalid() {
    const amount = this.amount;
    return (amount.touched || this.submitted) && amount.errors?.amountNotValid;
  }

  get amountInputError() {
    return (
      this.amountIsInvalid ||
      this.amountIsRequired ||
      this.amountRequiredForItemization ||
      this.maxAmountExceeded
    );
  }

  get fraudulentInvalid() {
    const fraudulent = this.fraudulent;
    return fraudulent.invalid && this.submitted;
  }

  get amountRequiredForItemization() {
    const amount = this.amount;

    return (
      (amount.invalid || amount.errors?.required) && !this.itemizationStatus
    );
  }

  get maxAmountExceeded() {
    const amount = this.amount;
    return (amount.dirty || amount.touched) && amount.errors?.maxAmountExceeded;
  }

  get vendorIsRequired() {
    const vendor = this.vendor;

    return vendor.invalid && this.submitted;
  }

  get descriptionIsRequired() {
    const description = this.description;

    return description.invalid && this.submitted;
  }

  get expenseTypeIsRequired() {
    const type = this.type;

    return type.invalid && this.submitted;
  }

  get jobCodeIsRequired() {
    const jobCode = this.jobCode;

    return jobCode.invalid && this.submitted;
  }

  /**
   * Other Properties
   */
  @ViewChild(FormGroupDirective, { static: false }) myForm;
  @ViewChild(DatepickerComponent) datepicker: DatepickerComponent;
  @ViewChild('jobCodeEl', { static: false }) jobCodeEl;
  @ViewChild('salesforceEl', { static: false }) salesforceEl;
  dismissing = false;
  showNote = false;
  showSalesforce = false;
  readonly maxLength = MAX_DESCRIPTION_LENGTH;
  unsubscribe$: Subject<void> = new Subject<any>();
  public modalType = this.store$.select(selectCurrentModal);

  url = `${environment.apiURL}/expenses/receipt/ocr-only`;
  method = 'post';
  showFraud = false;
  showBillable = false;

  public creditCardUser$ = this.store$.select(selectUserHasCreditCard);
  public isOcrMismatch$ = this.store$.select(selectExpenseOcrMismatch);

  ngOnInit() {
    this.modalType
      .pipe(
        takeUntil(this.unsubscribe$),
        tap((modalType) => {
          if (modalType === 'expense-new-modal-company-cc') {
            this.paymentType.setValue('user');
          } else {
            this.paymentType.setValue('personal');
          }
        })
      )
      .subscribe();
    if (!this.expenseTypes) {
      this.expenseTypes = this.personalExpenseTypes;
    }
    /**
     * All new expenses default to cash and today's date
     */

    this.transactionDate.setValue(new Date());
    this.formManager.register(this.formId, this.form);
    /**
     * REFACTOR NEEDED:
     * WHY: Ternary operator may be concise, but the items within tap are difficult to read
     */

    this.form.valueChanges
      .pipe(
        takeUntil(this.unsubscribe$),
        filter(() => !this.form.pristine && !this.dismissing),
        tap((value: any) => {
          const expenseForm = { ...value };
          expenseForm.transactionDate = expenseForm.transactionDate
            ? expenseForm.transactionDate
            : '';
          expenseForm.amount =
            expenseForm.amount && this.amount.valid
              ? this.utils.dollarsToCents(expenseForm.amount)
              : null;
          expenseForm.salesforceId = expenseForm.salesforceId
            ? +expenseForm.salesforceId
            : '0';
          this.formChange.emit(expenseForm);
        })
      )
      .subscribe();

    this.form.statusChanges
      .pipe(
        takeUntil(this.unsubscribe$),
        tap((value) => {
          const valid = value === 'VALID';
          this.statusChange.emit(valid);
        })
      )
      .subscribe();

    this.form
      .get('amount')
      .statusChanges.pipe(
        takeUntil(this.unsubscribe$),
        tap((value) => {
          const valid = value === 'VALID';
          this.amountStatusChange.emit(valid);
        })
      )
      .subscribe();
    this.form
      .get('type')
      .valueChanges.pipe(
        takeUntil(this.unsubscribe$),
        tap((value: any) => {
          // extract name of expenseType since formControl has id of the expense
          // I had to use == and not === since the value from valueChanges
          // function comes in as string even though it was number
          if (value) {
            const expenseTypeValue = this.expenseTypes.find(
              (p) => p.id == value
            );
            if (!expenseTypeValue) {
              this.type.setValue(null);
            }

            const showFraudPre = this.showFraud;
            this.showFraud = expenseTypeValue.name
              .toLowerCase()
              .includes('fraud')
              ? true
              : false;

            if (this.showFraud != showFraudPre) {
              if (showFraudPre) {
                this.resetFormAfterUncheckingFraud();
                this.fraudulent.reset();
                this.fraudulentExpense.emit(false);
              }
            }
            if (this.showFraud) {
              if (this.fraudulent.value === false) {
                // set fraudulent formControl to invalid
                this.fraudulent.setErrors({ incorrect: true });
              }
              this.fraudulent.setValidators(Validators.requiredTrue);
            } else {
              this.fraudulent.clearValidators();
              this.fraudulent.setErrors(null);
            }
          }
        })
      )
      .subscribe();

    this.form
      .get('jobCode')
      .valueChanges.pipe(
        takeUntil(this.unsubscribe$),
        tap((value: any) => {
          if (value) {
            this.showBillable = true;
            const jobCodeValue = this.jobCodes.find((p) => p.id == value);
            this.isBillable.setValue(jobCodeValue.company.billable);
          }
          // Set the jobCode vaue to default when the job code is removed
          else if(value === 0)  {
            this.jobCode.setValue(null);
          }
        })
      )
      .subscribe();

    this.form
      .get('salesforceId')
      .valueChanges.pipe(
        takeUntil(this.unsubscribe$),
        tap((value: any) => {
          const filteredCases = this.salesforceCases.filter((c) => c.id === value);
          // Find the matching jobCode record if custom case ahs approval jobCode
          if(filteredCases.length > 0 && filteredCases[0].expense_approval_job_code !== null) {
            const approval_jobCode = this.jobCodes.filter
              ((j) => j.id === filteredCases[0].expense_approval_job_code
            );
            // if the approval jobCode is part of the dropdown assign jobCode to expense
            if(approval_jobCode.length > 0) {
              this.jobCode.setValue(approval_jobCode[0].id);
            }
          }
        })
      )
      .subscribe();

    // Subscribe to selectReceiptData selector
    this.store$
      .pipe(
        select(selectReceiptData),
        takeUntil(this.unsubscribe$),
        tap((receiptData: Partial<Expense>) => {
          if (receiptData) {
            const dollars = this.convertToDollarsPipe.transform(receiptData.amount * 100);
            const updatedAmount = this.currencyPipe.transform(dollars);
            this.form.patchValue({
              vendor: receiptData.vendor,
              address: receiptData.address,
              amount: updatedAmount,
              transactionDate: receiptData.transactionDate,
              token: receiptData.token
            });
            if(!receiptData.transactionDate){
              this.transactionDate.setValue(new Date());
            }
          }
        })
      )
      .subscribe();
  }

  checkFraud(e) {
    this.fraudulentExpense.emit(e.target.checked);
  }

  resetFormAfterUncheckingFraud() {
    this.description.enable();

    this.amount.setValidators([
      Validators.required,
      CustomValidators.validAmount,
    ]);
    this.amount.updateValueAndValidity();

    this.vendor.setValidators([Validators.required]);
    this.vendor.updateValueAndValidity();

    this.transactionDate.setValidators([Validators.required]);
    this.transactionDate.updateValueAndValidity();

    this.jobCode.setValidators([Validators.required]);
    this.jobCode.updateValueAndValidity();

    this.type.enable();
    this.type.setValidators([Validators.required]);
    this.type.updateValueAndValidity();

    this.form.get('isBillable').enable();

    this.form.updateValueAndValidity();
  }

  receiptRequired() {
    // Receipt not required if its fraudulent expense
    if (this.showFraud) {
      return false;
    } else {
      return this.utils.checkIfForeignTransactionType(
        this.form.value,
        this.expenseTypes
      );
    }
  }

  onFileSelected(file) {
    this.receiptData.emit(file);
  }

  onToggleNote() {
    this.showNote = !this.showNote;
    this.form.get('message').setValue('');
  }

  onToggleSalesforce() {
    this.showSalesforce = !this.showSalesforce;
    if (!this.showSalesforce) {
      this.form.get('salesforceId').setValue('0');
    }
  }

  onFavoriteJobCode(id) {
    if (id) {
      this.favoriteJobCode.emit(id);
    }
  }

  onFavoriteSalesforceCase(id) {
    if (id) {
      this.favoriteSalesforce.emit(id);
    }
  }

  onReceiptUploaded(data) {
    if (data) {
      this.receiptData.emit(data);
    } else {
      this.receiptData.emit(this.autoSavedId);
    }
  }

  onDismiss(dismiss) {
    this.expenseService.checkForDismissChanges(this, dismiss, 'new');
  }

  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
    this.store$.dispatch(NewActions.clearReceiptData());
  }
}
